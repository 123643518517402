import React from 'react'
// import Layout from 'layout'
import { Link } from 'gatsby'
// import SEO from 'seo'

const NotFoundPage = ({ location }) => (
  <main className='default-page inner'>
    <h1 className='default-page__title size--1 text--serif--light'>
                Diese Seite existiert leider nicht!
    </h1>
    <div className='default-page__text size--base text--body text--sans'>
      <Link to='/'>
                    Gehe zur Startseite!
      </Link>
    </div>
  </main>
)

export default NotFoundPage
